@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap');

.App {
	font-family: 'Mulish', sans-serif;
}

/********* FONTS ***********/

h5 {
	color: #212529;
}

@supports (display: flex) {
	@media (max-width: 992px) {
		h5 {
			font-size: 16px;
		}
	}

	@media (max-width: 576px) {
		h5 {
			font-size: 14px;
		}

		p {
			font-size: 12px;
		}
	}
}

span {
	color: #b2b2b2;
	font-size: 12px;
}

i {
	font-size: 18px;
	color: #e7ab3d;
}

.icon-text {
	font-size: 18px;
}

/********* ELEMENTS ***********/

.super-small span {
	line-height: 1.5;
}

.border-orange {
	border: 5px solid #f3f3f3;
border-radius: 5px;
}
hr {
	color: #e7ab3d;
	width: 75px;
	border: 1.5px solid #e7ab3d;
	opacity: 100;
}

.grey-line {
	color: #ebebeb;
	border: 0.8px solid #ebebeb;
	width: 100%;
}

.no-underline {
	text-decoration: none;
	color: #000;
}

.zoom {
	border-radius: 10px;
	transform: scale3d(1, 1, 1);
	transition: transform 1s ease-in-out;
}

.accessories:hover .zoom,
.magic-div:hover .zoom,
.zoom:hover {
	cursor: pointer;
	transform: scale3d(1.05, 1.05, 1);
	transition: transform 1s ease-in-out;
}

.price {
	color: #e7ab3d;
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 0;
}

@supports (display: flex) {
	@media (max-width: 992px) {
		.price {
			font-size: 20px;
		}
	}

	@media (max-width: 576px) {
		.price {
			font-size: 18px;
		}
	}
}

.text-overlay {
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
}

.fa-star {
	font-size: 12px;
}

/********* BUTTONS ***********/

.btn-orange,
.btn-buy {
	text-transform: uppercase;
	font-weight: 700;
	padding: 10px 20px;
	border: none;
	transform: scale3d(1, 1, 1);
	transition: transform 1s ease-in-out;
	transition: background-color 1s, color 0.5s ease-in-out;

}

.btn-orange {
	background-color: #e7ab3d;
	color: #fff;
	font-size: 15px;
}

@supports (display: flex) {
	@media (max-width: 992px) {

		.btn-orange,
		.btn-buy {
			font-size: 14px;
		}
	}

	@media (max-width: 576px) {

		.btn-orange,
		.btn-buy {
			font-size: 12px;
		}
	}
}

.btn-buy {
	background-color: #f4f1e9;
	color: #212529;
}

.fa-credit-card {
	color: #212529;
	margin: 10px;
	transition: color 0.5s ease-in-out;
}

.btn-orange:hover(:not(.btn-categories)) {
	background-color: #c89230;
	transition: background-color 1s ease-in-out;

}

.btn-buy:hover {
	background-color: #e7ab3d;
	color: #fff;
	transition: background-color 1s, color 0.5s ease-in-out;
}

.btn-buy:hover .fa-credit-card {
	color: #fff;
	transition: color 0.5s ease-in-out;
}

.btn-in-cart {
	background-image: url('https://images-aigostar-cooking.s3.amazonaws.com/icon-basket-full.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-color: transparent;
	border: none;
}

.btn-not-cart {
	background-image: url('https://images-aigostar-cooking.s3.amazonaws.com/icon-basket-black.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-color: transparent;
	border: none;
}

.icon-button img {
	width: 30px;
	height: 30px;
}

.icon-button i {
	color: #fff;
	font-size: 28px;
	margin: 0 10px 0 10px;
}

@supports (display: flex) {
	@media (max-width: 992px) {
		.icon-button i {
			font-size: 24px;
		}
	}

	@media (max-width: 576px) {
		.icon-button i {
			font-size: 20px;
		}
	}
}

.moving img,
.moving i {
	transition: transform 0.7s, color 0.3s;
}

.moving {
	transition: transform 0.7s, color 0.3s;
	width: 260px;
}

@supports (display: flex) {
	@media (max-width: 576px) {
		.moving {
			width: 210px;
		}
	}
}

.moving:hover img,
.moving:hover i {
	transform: translateX(-170px);
	/* Mueve el ícono hacia la izquierda */
}

.moving:hover {
	color: transparent;
}

.product-card {
	height: 50px;
	width: 50px;
}

.table-btn {
	height: 30px;
	width: 30px;
	border-radius: 10px 0px;
	background-color: #f4f1e9;
	border: 7px solid #f4f1e9;
}

.table-btn:hover {
	background-color: #e7ab3d;
	border: 7px solid #e7ab3d;
}


.btn-login {
	color: #000;
	letter-spacing: 2px;
	text-decoration: underline;
	text-underline-offset: 10px;
	text-decoration-color: #ebebeb;
}

/*** MAGIC DIV ***/
.magic-div {
	position: relative;
	text-align: center;
}

.magic-div:hover {
	cursor: pointer;
}

.magic-div:hover .text-overlay {
	top: 50%;
	transition: top 1s ease-in-out;
}

/*** PRODUCT SMALL CARD ***/

/* INFO BUTTON */

.text-overlay.up-opaccity-effect {
	top: 110%;
	opacity: 0;
	transition: top 0.5s ease-in-out, opacity 0.7s ease-in-out;
}

.magic-div:hover .up-opaccity-effect {
	opacity: 1;
	top: 90%;
	transition: top 0.5s ease-in-out, opacity 0.7s ease-in-out;
}

/* CART BUTTON */

.magic-div:hover .btn-cart.right-to-left {
	opacity: 1;
	transition: right 0.5s ease-in-out, opacity 0.7s ease-in-out;
}

.magic-div:hover .right-to-left .margin {
	right: 15%;
}

.magic-div:hover .right-to-left {
	right: 0%;
}

.right-to-left {
	color: #212529;
	background-color: #f4f1e9;
	border: 7px solid #f4f1e9;
	border-radius: 10px 0px;
	position: absolute;
	top: 5%;
	right: -40px;
	opacity: 0;
	transition: right 0.5s ease-in-out, opacity 0.7s ease-in-out;
}

.right-to-left:hover {
	background-color: #e7ab3d;
	border-color: #e7ab3d;
}

/********* HOME SECTIONS ***********/

#banner,
#footer {
	background-color: #f4f1e9;
}

#footer {
	margin-top: 50px;
}

#register,
#login {
	height: 100vh;
}

/*** CATEGORIES section ***/

.btn-categories {
	margin: 0;
}

.magic-div:hover .btn-categories {
	transform: scale3d(1.1, 1.1, 1);
	transition: transform 1s ease-in-out;
}

.text-overlay.up-effect {
	top: 90%;
	transition: top 1s ease-in-out;
}

/*** SHIPING section ***/

.shipping {
	border: 2px solid #f3f4f0;
}

.shipping:not(:last-child) {
	border-right: 1px solid #f3f4f0;
}

.shipping i {
	font-size: 36px;
	margin-right: 20px;
}

@supports (display: flex) {
	@media (max-width: 576px) {
		.shipping h5 {
			font-size: 14px;
		}

		.shipping p {
			font-size: 12px;
		}

		.shipping i {
			font-size: 24px;
		}
	}
}

/********* PRODUCT DETAIL view ***********/
.second-nav-bar-container {
	margin: 0 100px;
}

@supports (display: flex) {
	@media (max-width: 992px) {
		.second-nav-bar-container {
			margin: 10px 80px;
		}
	}

	@media (max-width: 768px) {
		.second-nav-bar-container {
			margin: 10px 60px;
		}
	}

	@media (max-width: 576px) {
		.second-nav-bar-container {
			margin: 10px 40px;
		}
	}
}

.second-navbar {
	text-decoration: none;
	color: #808080;
	font-size: 11px;
}

.fa-brands {
	color: #212529;
}

.fa-house {
	font-size: 10px;
}

.select-size,
.select-color {
	border-radius: 10px;
	cursor: pointer;
}

.select-size {
	border: 1px solid #ebebeb;
}

.select-color {
	border: 1px solid transparent;
}

.select-size.active,
.select-color.active {
	border-color: #e7ab3d;
}

.quantity {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	border: 1px solid #f4f1e9;
}

.quantity p {
	margin: 0;
}

.quantity-text {
	cursor: pointer;
	font-size: 20px;
}

.quantity-text:hover {
	font-size: 24px;
}

.total-container {
	margin: 20px 150px;
	display: flex;
	justify-content: flex-end;
}

.total {
	background-color: #f3f3f3;
	border: 1px solid #ebebeb;
	display: flex;
	justify-content: space-between;
}

.start-buy {
	background-color: #252525;
	border: none;
	color: #ffffff;
	text-transform: uppercase;
	text-align: center;
	font-weight: 700;
	cursor: pointer;
	transition: background-color 0.5s;
}

.start-buy:hover{
	background-color: #e7ab3d;
}
.total span {
	font-weight: 700;
	font-size: 20px;
}

@supports (display: flex) {
	@media (max-width: 576px) {

		.total span,
		.start-buy {
			font-size: 14px;
		}
	}
}

.especifications {
	display: flex;
	justify-content: space-evenly;
}

.especifications h5,
.pointer {
	cursor: pointer;
}

.pointer:hover {
	color: red;
}

.especifications .active {
	text-decoration: underline;
	text-underline-offset: 19px;
	text-decoration-color: #c89230;
}

.dialog-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.even {
	background-color: #f4f1e9;
}

.forget-password {
	font-size: 12px;
	color: #797979;
}

/********* CART VIEW ***********/
.cart-table {
	border: 1px solid #ebebeb;
	margin: 0 150px;
}

.send-details {
	margin: 0 150px;
	display: flex;
	justify-content: center;
}

@supports (display: flex) {
	@media (max-width: 992px) {

		.cart-table,
		.total-container,
		.send-details {
			margin: 10px 100px;
		}
	}

	@media (max-width: 768px) {

		.cart-table,
		.total-container,
		.send-details {
			margin: 10px 80px;
		}
	}

	@media (max-width: 576px) {

		.cart-table,
		.total-container,
		.send-details {
			margin: 10px 20px;
		}
	}
}

.table-box {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
}

@supports (display: flex) {
	@media (max-width: 576px) {

		.table-box h5,
		.table-box:not(.price) {
			font-size: 10px;
		}

		.table-box.price {
			font-size: 14px;
		}
	}
}

.cart-table-header {
	border-bottom: 1px solid #ebebeb;
	overflow-x: auto;
	max-width: 100%;
}


.gradient-custom {
	background: -webkit-linear-gradient(left, #e7ab3d, #000);
	margin-top: 25px;
}

.card-custom {
	border-bottom-left-radius: 10% 50%;
	border-top-left-radius: 10% 50%;
	background-color: #f4f1e9;
	/* border: 1px solid #ebebeb; */
}

/* .form-outline {
	padding: 0 20px;
} */
.input-custom {
	background-color: white;
}

input {
	height: 40px;
	border: 1px solid #ebebeb;
	width: 100%;
	padding-left: 10px;
}

textarea:focus,
input:focus {
	outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

.white-text {
	color: hsl(52, 0%, 98%);
	font-weight: 100;
	font-size: 14px;
}

.back-button {
	background-color: hsl(52, 0%, 98%);
	font-weight: 700;
	color: black;
	margin-top: 50px;
}

.carrousel {
	overflow-x: auto;
	/* Enable horizontal scrolling */
	scroll-behavior: smooth;
	/* Add smooth scrolling */
	/* white-space: nowrap; */
}


/* Style for each item in the horizontal scroll */
.carrousel .text-center {
	flex: 0 0 auto;
	/* Allow items to shrink or grow as needed */
	margin-bottom: 20px;
}

/* Optional: Add scrollbar styling */
@supports (display: flex) {
	@media (max-width: 768px) {
		.carrousel::-webkit-scrollbar {
			height: 8px;
		}
	}
}

/* .carrousel::-webkit-scrollbar {
	height: 8px;
  } */

.carrousel::-webkit-scrollbar-thumb {
	background-color: #e7ab3d;
	border-radius: 8px;
}

.carrousel::-webkit-scrollbar-track {
	background-color: #ebebeb;
	border-radius: 8px;
}

.accesorie-kit {
	background-color: #fff;
	border: 1px solid #e7ab3d;
	border-radius: 8px;
}

.carrousel img {
	object-fit: fill;
	max-width: 100%;
	max-height: 100%;
}

.img-airfryer {
	object-fit: contain
}

@supports (display: flex) {
	@media (max-width: 992px) {
		.product-card-container h5 {
			font-size: 14px;
		}

		.product-card-container span {
			font-size: 10px;
		}
	}

	@media (max-width: 768px) {
		.product-card-container h5 {
			font-size: 16px;
		}

		.product-card-container span {
			font-size: 12px;
		}
	}
}

@supports (display: flex) {
	@media (max-width: 992px) {
		h2 {
			font-size: 22px;
		}
	}

	@media (max-width: 768px) {
		h2 {
			font-size: 20px;
		}
	}

	@media (max-width: 576px) {
		h2 {
			font-size: 18px;
		}
	}
}

.footer-link {
	text-decoration: none;
	color: #000;
}

.footer-link:hover {
	color: #e7ab3d;
}

.payment-container {
	background-color: #f3f3f3;
	border: 1px solid #ebebeb;
}
.order-details{
	border: 1px solid #e7ab3d;
}
